import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DefaultNavBar from "components/Navbars/DefaultNavBar.js";
import ContactUsHeader from "components/Headers/ContactUs.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import ContactForm from "components/Forms/ContactForm.js";

import GoogleMap from '../../index-sections/GoogleMap.js';

function HomePage(props) {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <DefaultNavBar {...props} />
      <div className="wrapper">
        <ContactUsHeader {...props} />
        <div className="section section-contact-us">
          <Container>
          <Row>
            <Col className="ml-auto mr-auto justify-content-left mb-5" md="5" sm="12">
              <h1>Contact Us</h1>
                  <p className="font-weight-normal">Unlimited Transportation
                  <br />
                  2151-2181 International St
                  <br />
                  Columbus, Ohio 43228
                  <br />
                  Office: (614) 351-0400
                  <br/>
                  Fax: (614) 351-0402
                  <br/>
                  Email: 
                  <a
                    style={{color:"#158FBF"}}
                    href="mailto:info@unlimitedtransportation.com"> info@unlimitedtransportation.com</a></p>
              <h2 className="pt-4">We'd Love to Hear from You!</h2>

              <ContactForm />
              </Col>
              <Col style={{minHeight:"450px"}} className="ml-auto mr-auto pl-0" md="6" sm="12">
                <GoogleMap />
              </Col>
            </Row>
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default HomePage;
