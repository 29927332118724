import React from 'react';
import { useForm } from 'react-hook-form';

import {
  Input,
  Form,
  Col,
  Row,
  FormGroup,
  Label,
  Button,
  FormFeedback
  } from "reactstrap";

function EmploymentHistory(props) {
  const { handleFormEntry, updatePillButtons } = props;

  const handleInputChange = e => {
    handleFormEntry(e)
  }

  const { register, errors, handleSubmit } = useForm({
    mode: "all",
  });

  const handleClick = async (data, e) => {
    let pill = 4;
    if (e.target.id === "next") {
      pill++
      updatePillButtons(pill)
    } else if (e.target.id === "previous") {
      pill--
      updatePillButtons(pill)
    }
  }

  return(
    <Form>
      <p className="lead">Employment History</p>

      <p className="mt-5 mb-n1 category">Employer #1</p>
      {/* */}
      <Label>Employer Name*</Label>
      <FormGroup>
        <Input
          className={errors.employerName1 && "is-invalid"}
          innerRef={register({ required: true})}
          name="employerName1"
          placeholder="Employer Name"
          type="text"
          onChange={handleInputChange}
        ></Input>
        <FormFeedback>This is required.</FormFeedback>
      </FormGroup>
      
      {/* */}
      <Label>Job Title*</Label>
      <FormGroup>
        <Input
          className={errors.jobTitle1 && "is-invalid"}
          innerRef={register({ required: true})}
          name="jobTitle1"
          placeholder="Job Title"
          type="text"
          onChange={handleInputChange}
        ></Input>
        <FormFeedback>This is required.</FormFeedback>
      </FormGroup>
      
      {/* */}
      <Label>Supervisor Name*</Label>
      <FormGroup>
        <Input
          className={errors.supervisorName1 && "is-invalid"}
          innerRef={register({ required: true})}
          name="supervisorName1"
          placeholder="Supervisor Name"
          type="text"
          onChange={handleInputChange}
        ></Input>
        <FormFeedback>This is required.</FormFeedback>
      </FormGroup>  

      {/* */}
      <Label>Street Address*</Label>
      <FormGroup>
        <Input
          className={errors.employerStreetAddress1 && "is-invalid"}
          innerRef={register({ required: true})}
          name="employerStreetAddress1"
          placeholder="Employer Street Address"
          type="text"
          onChange={handleInputChange}
        ></Input>
        <FormFeedback>This is required.</FormFeedback>
      </FormGroup>

    {/* */}
    <Row>
      <Col xl="6">
        <Label>City, State*</Label>
        <FormGroup>
          <Input
            className={errors.employerCityState1 && "is-invalid"}
            innerRef={register({ required: true})}
            name="employerCityState1"
            placeholder="City, State"
            type="text"
            onChange={handleInputChange}
          ></Input>
          <FormFeedback>This is required.</FormFeedback>
        </FormGroup>              
      </Col>
            
      {/* */}
      <Col xl="6">
        <Label>Zip Code*</Label>
        <FormGroup>
          <Input
            className={errors.employerZipCode1 && "is-invalid"}
            innerRef={register({ required: true})}
            name="employerZipCode1"
            placeholder="Zip Code"
            type="text"
            onChange={handleInputChange}
          ></Input>
          <FormFeedback>This is required.</FormFeedback>
        </FormGroup>
      </Col>
    </Row>            
    
    {/* */}
    <Label>Employer Telephone*</Label>
    <FormGroup>
      <Input
        className={errors.employerTelephone1 && "is-invalid"}
        innerRef={register({ required: true})}
        name="employerTelephone1"
        placeholder="Employer Telephone"
        type="tel"
        onChange={handleInputChange}
      ></Input>
      <FormFeedback>This is required.</FormFeedback>
    </FormGroup>

    {/* */}
    <Label>Dates Employed*</Label>
    <FormGroup>
      <Input
        className={errors.datesEmployed1 && "is-invalid"}
        innerRef={register({ required: true})}
        name="datesEmployed1"
        placeholder="Dates Employed"
        type="text"
        onChange={handleInputChange}
      ></Input>
      <FormFeedback>This is required.</FormFeedback>
    </FormGroup>

    {/* */}
    <Label>Reason for Leaving*</Label>
    <FormGroup>
      <Input
        className={errors.leavingReason1 && "is-invalid"}
        innerRef={register({ required: true})}
        name="leavingReason1"
        placeholder="Reasons for Leaving"
        type="text"
        onChange={handleInputChange}
      ></Input>
      <FormFeedback>This is required.</FormFeedback>
    </FormGroup>

    <p className="mt-5 mb-n1 category">Employer #2</p>
      
      {/* */}
      <Label>Employer Name*</Label>
      <FormGroup>
        <Input
          className={errors.employerName2 && "is-invalid"}
          innerRef={register({ required: true})}
          name="employerName2"
          placeholder="Employer Name"
          type="text"
          onChange={handleInputChange}
        ></Input>
        <FormFeedback>This is required.</FormFeedback>
      </FormGroup>
      
      {/* */}
      <Label>Job Title*</Label>
      <FormGroup>
        <Input
          className={errors.jobTitle2 && "is-invalid"}
          innerRef={register({ required: true})}
          name="jobTitle2"
          placeholder="Job Title"
          type="text"
          onChange={handleInputChange}
        ></Input>
        <FormFeedback>This is required.</FormFeedback>
      </FormGroup>
      
      {/* */}
      <Label>Supervisor Name*</Label>
      <FormGroup>
        <Input
          className={errors.supervisorName2 && "is-invalid"}
          innerRef={register({ required: true})}
          name="supervisorName2"
          placeholder="Supervisor Name"
          type="text"
          onChange={handleInputChange}
        ></Input>
        <FormFeedback>This is required.</FormFeedback>
      </FormGroup>  

      {/* */}
      <Label>Street Address*</Label>
      <FormGroup>
        <Input
          className={errors.employerStreetAddress2 && "is-invalid"}
          innerRef={register({ required: true})}
          name="employerStreetAddress2"
          placeholder="Employer Street Address"
          type="text"
          onChange={handleInputChange}
        ></Input>
        <FormFeedback>This is required.</FormFeedback>
      </FormGroup>

    {/* */}
    <Row>
      <Col xl="6">
        <Label>City, State*</Label>
        <FormGroup>
          <Input
            className={errors.employerCityState2 && "is-invalid"}
            innerRef={register({ required: true})}
            name="employerCityState2"
            placeholder="City, State"
            type="text"
            onChange={handleInputChange}
          ></Input>
          <FormFeedback>This is required.</FormFeedback>
        </FormGroup>              
      </Col>
            
      {/* */}
      <Col xl="6">
        <Label>Zip Code*</Label>
        <FormGroup>
          <Input
            className={errors.employerZipCode2 && "is-invalid"}
            innerRef={register({ required: true})}
            name="employerZipCode2"
            placeholder="Zip Code"
            type="text"
            onChange={handleInputChange}
          ></Input>
          <FormFeedback>This is required.</FormFeedback>
        </FormGroup>
      </Col>
    </Row>            
    
    {/* */}
    <Label>Employer Telephone*</Label>
    <FormGroup>
      <Input
        className={errors.employerTelephone2 && "is-invalid"}
        innerRef={register({ required: true})}
        name="employerTelephone2"
        placeholder="Employer Telephone"
        type="tel"
        onChange={handleInputChange}
      ></Input>
      <FormFeedback>This is required.</FormFeedback>
    </FormGroup>

    {/* */}
    <Label>Dates Employed*</Label>
    <FormGroup>
      <Input
        className={errors.dateEmployed2 && "is-invalid"}
        innerRef={register({ required: true})}
        name="datesEmployed2"
        placeholder="Dates Employed"
        type="text"
        onChange={handleInputChange}
      ></Input>
      <FormFeedback>This is required.</FormFeedback>
    </FormGroup>

    {/* */}
    <Label>Reason for Leaving*</Label>
    <FormGroup>
      <Input
        className={errors.leavingReason2 && "is-invalid"}
        innerRef={register({ required: true})}
        name="leavingReason2"
        placeholder="Reasons for Leaving"
        type="text"
        onChange={handleInputChange}
      ></Input>
      <FormFeedback>This is required.</FormFeedback>
    </FormGroup>

    <p className="mt-5 mb-n1 category">Employer #3</p>
      
      {/* */}
      <Label>Employer Name*</Label>
      <FormGroup>
        <Input
          className={errors.employerName3 && "is-invalid"}
          innerRef={register({ required: true})}
          name="employerName3"
          placeholder="Employer Name"
          type="text"
          onChange={handleInputChange}
        ></Input>
        <FormFeedback>This is required.</FormFeedback>
      </FormGroup>
      
      {/* */}
      <Label>Job Title*</Label>
      <FormGroup>
        <Input
          className={errors.jobTitle3 && "is-invalid"}
          innerRef={register({ required: true})}
          name="jobTitle3"
          placeholder="Job Title"
          type="text"
          onChange={handleInputChange}
        ></Input>
        <FormFeedback>This is required.</FormFeedback>
      </FormGroup>
      
      {/* */}
      <Label>Supervisor Name*</Label>
      <FormGroup>
        <Input
          className={errors.supervisorName3 && "is-invalid"}
          innerRef={register({ required: true})}
          name="supervisorName3"
          placeholder="Supervisor Name"
          type="text"
          onChange={handleInputChange}
        ></Input>
        <FormFeedback>This is required.</FormFeedback>
      </FormGroup>  

      {/* */}
      <Label>Street Address*</Label>
      <FormGroup>
        <Input
          className={errors.employerStreetAddress3 && "is-invalid"}
          innerRef={register({ required: true})}
          name="employerStreetAddress3"
          placeholder="Employer Street Address"
          type="text"
          onChange={handleInputChange}
        ></Input>
        <FormFeedback>This is required.</FormFeedback>
      </FormGroup>

    {/* */}
    <Row>
      <Col xl="6">
        <Label>City, State*</Label>
        <FormGroup>
          <Input
            className={errors.employerCityState3 && "is-invalid"}
            innerRef={register({ required: true})}
            name="employerCityState3"
            placeholder="City, State"
            type="text"
            onChange={handleInputChange}
          ></Input>
          <FormFeedback>This is required.</FormFeedback>
        </FormGroup>              
      </Col>
            
      {/* */}
      <Col xl="6">
        <Label>Zip Code*</Label>
        <FormGroup>
          <Input
            className={errors.employerZipCode3 && "is-invalid"}
            innerRef={register({ required: true})}
            name="employerZipCode3"
            placeholder="Zip Code"
            type="text"
            onChange={handleInputChange}
          ></Input>
          <FormFeedback>This is required.</FormFeedback>
        </FormGroup>
      </Col>
    </Row>            
    
    {/* */}
    <Label>Employer Telephone*</Label>
    <FormGroup>
      <Input
        className={errors.employerTelephone3 && "is-invalid"}
        innerRef={register({ required: true})}
        name="employerTelephone3"
        placeholder="Employer Telephone"
        type="text"
        onChange={handleInputChange}
      ></Input>
      <FormFeedback>This is required.</FormFeedback>
    </FormGroup>

    {/* */}
    <Label>Dates Employed*</Label>
    <FormGroup>
      <Input
        className={errors.dateEmployed3 && "is-invalid"}
        innerRef={register({ required: true})}
        name="datesEmployed3"
        placeholder="Dates Employed"
        type="text"
        onChange={handleInputChange}
      ></Input>
      <FormFeedback>This is required.</FormFeedback>
    </FormGroup>

    {/* */}
    <Label>Reason for Leaving*</Label>
    <FormGroup>
      <Input
        className={errors.leavingReason3 && "is-invalid"}
        innerRef={register({ required: true})}
        name="leavingReason3"
        placeholder="Reasons for Leaving"
        type="text"
        onChange={handleInputChange}
      ></Input>
      <FormFeedback>This is required.</FormFeedback>
    </FormGroup>

    <p className="lead mt-5">References</p>
    <p className="mt-4 mb-n1 category">Reference #1</p>

    {/* */}
    <Label>Reference Name*</Label>
    <FormGroup>
      <Input
      className={errors.referenceName1 && "is-invalid"}
      innerRef={register({ required: true})}
        name="referenceName1"
        placeholder="Reference Name"
        type="text"
        onChange={handleInputChange}
      ></Input>
      <FormFeedback>This is required.</FormFeedback>
    </FormGroup>
  
    {/* */}
    <Label>Contact Information*</Label>
    <FormGroup>
      <Input
        className={errors.referenceContact1 && "is-invalid"}
        innerRef={register({ required: true})}
        name="referenceContact1"
        placeholder="Contact Information"
        type="text"
        onChange={handleInputChange}
      ></Input>
      <FormFeedback>This is required.</FormFeedback>
    </FormGroup>

    <p className="mt-5 mb-n1 category">Reference #2</p>
    {/* */}
    <Label>Reference Name*</Label>
    <FormGroup>
      <Input
        className={errors.referenceName2 && "is-invalid"}
        innerRef={register({ required: true})}
        name="referenceName2"
        placeholder="Reference Name"
        type="text"
        onChange={handleInputChange}
      ></Input>
      <FormFeedback>This is required.</FormFeedback>
    </FormGroup>
  
    {/* */}
    <Label>Contact Information*</Label>
    <FormGroup>
      <Input
        className={errors.referenceContact2 && "is-invalid"}
        innerRef={register({ required: true})}
        name="referenceContact2"
        placeholder="Contact Information"
        type="text"
        onChange={handleInputChange}
      ></Input>
      <FormFeedback>This is required.</FormFeedback>
    </FormGroup>

    <p className="mt-5 mb-n1 category">Reference #3</p>
    {/* */}
    <Label>Reference Name*</Label>
    <FormGroup>
      <Input
        className={errors.referenceName3 && "is-invalid"}
        innerRef={register({ required: true})}
        name="referenceName3"
        placeholder="Reference Name"
        type="text"
        onChange={handleInputChange}
      ></Input>
      <FormFeedback>This is required.</FormFeedback>
    </FormGroup>
  
    {/* */}
    <Label>Contact Information*</Label>
    <FormGroup>
      <Input
        className={errors.referenceContact3 && "is-invalid"}
        innerRef={register({ required: true})}
        name="referenceContact3"
        placeholder="Contact Information"
        type="text"
        onChange={handleInputChange}
      ></Input>
      <FormFeedback>This is required.</FormFeedback>
    </FormGroup>

    {/* Next and Previous Buttons */}
    <Row className="d-flex justify-content-center">
      <Col md="12" className="d-flex justify-content-between mb-5 pt-3">
        <Button
          color="info"
          id="previous"
          onClick={e => handleClick(null, e)}
        >Previous</Button>
        <Button
          color="info"
          id="next"
          onClick={handleSubmit(handleClick)}
        >Next
        </Button>
      </Col>
    </Row>
  </Form>
  );
}

export default EmploymentHistory;