import React from 'react';

import {
    TabPane,
    Row,
    Col,
  } from "reactstrap";

function WarehousingTab() {
  return(
    <TabPane tabId="pills2">
      <Col className="ml-auto mr-auto" md="10">
        <Row className="collections justify-content-center">
          <Col md="8">
            <h4 className="text-justify">
              We have a 17000 sq ft warehouse available for your warehousing and storage needs.
              Video surveillance, 5 point alarm system, and security ensure that your shipments are safe and secure at all times.
            </h4>
            <h3>
              CFS Station
            </h3>
            <ul>
              <li>De-vanning</li>
              <li>Sorting</li>
              <li>Palletizing</li>
              <li>Assisting with Customs Clearance</li>
              <li>First & Final mile delivery</li>
            </ul>
          </Col>
        </Row>

        <Row className="collections justify-content-center">
          <Col md="8">
              <img
                alt="Warehousing"
                className="img-raised mt-5 w-100"
                src={require("assets/img/services/IMG_0480.jpg")}
              />
            </Col>
          </Row>
        </Col>
      </TabPane>
    );
};

export default WarehousingTab;
