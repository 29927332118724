import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function AboutUsHeader() {

  return (
    <>
      <div className="page-header clear-filter page-header-small ">
        <div>
          <Container className="d-flex align-items-center justify-content-around mb-4">
            <i className="fas fa-map-marked"></i>
            <i className="fas fa-shipping-fast"></i>
            <h1 className="title font-weight-regular">About Us</h1>
            <i className="fas fa-dolly"></i>
            <i className="fas fa-people-carry"></i>
          </Container>
        </div>
      </div>
    </>
  );
}

export default AboutUsHeader;
