import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DefaultNavBar from "components/Navbars/DefaultNavBar.js";
import AboutUsHeader from "components/Headers/AboutUsHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function AboutUsPage(props) {
  const { width: windowWidth } = useWindowDimensions();

  React.useEffect(() => {
    document.body.classList.add("about-us-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <DefaultNavBar {...props} />
      <div className="wrapper">
        <AboutUsHeader />
        <div className="section">
          <Container className="mission-section">
            <Row className="justify-content-center">
              <Col md="10" className="text-justify mb-5">
                <p>
                  Our success is attributed to our commitment
                  to the customer first and foremost. Unlimited Transportation
                  is driven to be the very best in the logistics industry and
                  we look forward to handling your specific needs.
                </p>
                <p>
                  Our mission is to deliver excellent service that directly
                  addresses our customers' needs, maintain an open dialogue
                  with our customers and incorporate their suggestions and
                  desires into our future service; remain on the cutting edge
                  of technology and continue to provide new and innovative
                  solutions to our customers' present and future needs;
                  make service quality the responsibility of every employee
                  and take pride in all services we provide.
                </p>

              </Col>
            </Row>
                <h2 className="text-center mb-5">Our People</h2>
                <Row className="d-flex">
                  <Col>
                    <div
                      className="image-container"
                      style={{ backgroundImage: 'url('+require('assets/img/employees/group.jpg')+')' }}
                    />
                  </Col>
                  {/* <Col className="ml-auto mr-auto" md="4" lg="3">
                    <div
                      className="image-container"
                      style={{ backgroundImage: 'url('+require('assets/img/employees/brian.jpg')+')' }}
                    />
                  </Col>
                  <Col className="ml-auto mr-auto" md="4" lg="3">
                  <div
                      className="image-container"
                      style={{ backgroundImage: 'url('+require('assets/img/employees/kyle.jpg')+')' }}
                    />
                  </Col> */}
                </Row> 
          </Container>
        </div>
        <div className="section who-we-are">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <p className="font-weight-normal text-justify">
                  We are a dedicated team of logistics experts who
                  have left behind the outdated shipping methods of
                  the previous century. We ship the unshippable –
                  and deliver it the way you want. We’ve built our
                  reputation on <b className="font-weight-bold">excellence in shipping and logistics</b>,
                  with a constant focus on serving our customers
                  precisely and effeciently.
                <br/>
                <br/>
                  We strive to serve our community of customers, business partners,
                  and employees. We are always excited to meet new people who share
                  our passion for hard work to solve big problems. Send us a note and say hello.
                </p>
                <Button 
                  className="btn-square btn-outline-light ml-auto mr-auto d-flex mt-4" 
                  size="lg"
                  onClick={e => {
                    e.preventDefault();
                    window.open('/contact-us','_blank');
                  }}
                >
                  Contact Us
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default AboutUsPage;
