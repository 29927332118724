import React from 'react';

import {
    TabPane,
    Row,
    Col,
  } from "reactstrap";

function SpecialDeliveriesTab() {
  return(
    <TabPane tabId="pills1">
      <Col className="ml-auto mr-auto" md="10">
      <Row className="collections justify-content-center">
          <Col md="8">
              <h4 className="text-justify">
              Your client has a shipment which requires “special services”.
              We will set up delivery and make sure that all the arrangements are not only met but carried through beginning to end.
            </h4>
            </Col>
          </Row>

          <Row className="collections justify-content-center">
          <Col md="8">
            <img
              alt="Special Deliveries"
              className="img-raised mt-5 w-100"
              src={require("assets/img/services/specialDeliveries.jpg")}
            />
          </Col>
        </Row>
      </Col>
    </TabPane>
  );
};

export default SpecialDeliveriesTab;