import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import moment from 'moment';

import {
  Input,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Row,
  Col,
  Button
  } from "reactstrap";

import DriverQuestions from './DriverQuestions';
import OfficeQuestions from './OfficeQuestions';

function JobQuestionnaire(props) {
  const { formData: { position }, handleFormEntry, updatePillButtons } = props;

  const handleInputChange = e => {
    handleFormEntry(e)
  }

  const { register, errors, handleSubmit } = useForm({
    mode: "all",
  });

  const handleClick = async (data, e) => {
    let pill = 2;
    if (e.target.id === "next") {
      pill++
      updatePillButtons(pill)
    } else if (e.target.id === "previous") {
      pill--
      updatePillButtons(pill)
    }
  }

  const todayDate = moment().format('YYYY-MM-DD');

    return(
      <FormProvider register={register} errors={errors}>
        <Form>
          <p className="lead mb-4">Job Questionnaire</p>
          {/*  */}
          <Label>Please list below the skills and qualifications you possess for the position for which you are applying.*</Label>
          <FormGroup>
            <Input
              className={"text-area " + (errors.skills && "is-invalid")}
              onChange={handleInputChange}  
              placeholder="Please list any skills or qualifications for the positions."
              rows="4"
              name="skills"
              type="textarea"
              innerRef={register({ required: true })}
            ></Input>
          {errors.skills && <FormFeedback>This is required.</FormFeedback>}
          </FormGroup>

          {/* */}
          <Label>How did you hear about this position?</Label>
          <FormGroup>
            <Input
              name="heardAboutPosition"
              placeholder="Word of mouth, job board, etc."
              type="text"
              onChange={handleInputChange}
            ></Input>
          </FormGroup>
          
          {/* */}
          <Label>What days are you available for work?*</Label>
          <FormGroup>
            <Input
              name="availableDays"
              placeholder="Mon, Tues, Fri, etc."
              type="text"
              onChange={handleInputChange}
              innerRef={register({ required: true })}
              className={errors.availableDays && "is-invalid"}
            ></Input>
            <FormFeedback>This is required.</FormFeedback>
          </FormGroup>

          {/* */}
          <Label>What hours or shifts are you available for work?*</Label>
          <FormGroup>
            <Input
              name="availableTimes"
              placeholder="Nights, days, evenings, etc."
              type="text"
              onChange={handleInputChange}
              innerRef={register({ required: true })}
              className={errors.availableTimes && "is-invalid"}
            ></Input>
            <FormFeedback>This is required.</FormFeedback>
          </FormGroup>

          {/* */}
          <Label>Desired Salary</Label>
          <FormGroup>
            <Input
              name="desiredSalary"
              placeholder="Desired Salary"
              type="text"
              onChange={handleInputChange}
            ></Input>
          </FormGroup>

          {/* */}
          <FormGroup>
           <Label>On what date can you start working if you are hired?*</Label>
            <Input
              min={todayDate}
              name="startDate"
              type="date"
              onChange={handleInputChange}
              placeholder="Desired Start Date"
              innerRef={register({ required: true })}
              className={errors.startDate && "is-invalid"}
            />
            <FormFeedback>This is required.</FormFeedback>
          </FormGroup>

          {/* */}
          <Label>If needed, are you available to work overtime?*</Label>
          <ErrorMessage 
            errors={errors} 
            name="overtime" 
            message="This is required." 
            as={
              <p 
                className="invalid-feedback"
                style={{display:"block"}}
              >
                This is required.
              </p>
            }
          />
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="Yes"
                name="overtime"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                Yes
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="No"
                name="overtime"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                No
            </Label>
          </FormGroup>

          {/* */}
          <Label>Do you have reliable transportation to and from work?*</Label>
          <ErrorMessage 
            errors={errors} 
            name="reliableTransport" 
            message="This is required." 
            as={
              <p 
                className="invalid-feedback"
                style={{display:"block"}}
              >
                This is required.
              </p>
            }
          />
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="Yes"
                name="reliableTransport"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                Yes
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="No"
                name="reliableTransport"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                No
            </Label>
          </FormGroup>

          {/* */}
          <Label>Have you ever applied to or worked for Unlimited Transportation Corp before?*</Label>
          <ErrorMessage 
            errors={errors} 
            name="appliedBefore" 
            message="This is required." 
            as={
              <p 
                className="invalid-feedback"
                style={{display:"block"}}
              >
                This is required.
              </p>
            }
          />
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="Yes"
                name="appliedBefore"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                Yes
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="No"
                name="appliedBefore"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                No
            </Label>
          </FormGroup>

          {/* */}
          <Label>If yes, when?</Label>
          <FormGroup>
            <Input
              name="appliedWhen"
              placeholder="If yes, when?"
              type="date"
              onChange={handleInputChange}
            ></Input>
          </FormGroup>

          {/* */}
          <Label>Do you have any friends, relatives, or acquaintances working for Unlimited Transporation Corp?*</Label>
          <ErrorMessage 
            errors={errors} 
            name="knowEmployees" 
            message="This is required." 
            as={
              <p 
                className="invalid-feedback"
                style={{display:"block"}}
              >
                This is required.
              </p>
            }
          />
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="Yes"
                name="knowEmployees"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                Yes
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="No"
                name="knowEmployees"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                No
            </Label>
          </FormGroup>

          {/* */}
          <Label>If yes, state name and relationship.</Label>
          <FormGroup>
            <Input
              name="employeeRelationships"
              placeholder="If yes, state name and relationship"
              type="text"
              onChange={handleInputChange}
            ></Input>
          </FormGroup>

          {/* */}
          <Label>Are you 18 years of age or older?*</Label>
          <ErrorMessage 
            errors={errors} 
            name="olderThan18" 
            message="This is required." 
            as={
              <p 
                className="invalid-feedback"
                style={{display:"block"}}
              >
                This is required.
              </p>
            }
          />
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="Yes"
                name="olderThan18"
                onChange={handleInputChange}
                type="radio"
              ></Input>
                <span className="form-check-sign"></span>
                Yes
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="No"
                name="olderThan18"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                No
            </Label>
          </FormGroup>

          {/* */}
          <Label>Are you a U.S. citizen or approved to work in the United States?*</Label>
          <ErrorMessage 
            errors={errors} 
            name="citizen" 
            message="This is required." 
            as={
              <p 
                className="invalid-feedback"
                style={{display:"block"}}
              >
                This is required.
              </p>
            }
          />
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="Yes"
                name="citizen"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                Yes
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="No"
                name="citizen"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                No
            </Label>
          </FormGroup>

          {/* */}
          <Label>What document can you provide as proof of citizenship or legal status?*</Label>
          <FormGroup>
            <Input
              className={errors.proofOfCitizenship && "is-invalid"}
              name="proofOfCitizenship"
              placeholder="Document for Proof of Citizenship/Legal Status"
              type="text"
              onChange={handleInputChange}
              innerRef={register({ required: true })}
            ></Input>
            <FormFeedback>This is required.</FormFeedback>            
          </FormGroup>

          {/* */}
          <Label>Will you consent to a mandatory controlled substance test?*</Label>
          <ErrorMessage 
            errors={errors} 
            name="substanceTest" 
            message="This is required." 
            as={
              <p 
                className="invalid-feedback"
                style={{display:"block"}}
              >
                This is required.
              </p>
            }
          />
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="Yes"
                name="substanceTest"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                Yes
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="No"
                name="substanceTest"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                No
            </Label>
          </FormGroup>

          {/* */}
          <Label>Do you have any condition which would require job accommodations?*</Label>
          <ErrorMessage 
            errors={errors} 
            name="accommodations" 
            message="This is required." 
            as={
              <p 
                className="invalid-feedback"
                style={{display:"block"}}
              >
                This is required.
              </p>
            }
          />
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="Yes"
                name="accommodations"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                Yes
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="No"
                name="accommodations"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                No
            </Label>
          </FormGroup>

          {/* */}
          <Label>If yes, please describe the accommodations required.</Label>
          <FormGroup>
            <Input
              name="requiredAccommodations"
              placeholder="Required Accommodations"
              type="text"
              onChange={handleInputChange}
            ></Input>
          </FormGroup>

          {/* */}
          <Label>Have you ever been convicted of a criminal offense (felony or misdemeanor)?*</Label>
          <ErrorMessage 
            errors={errors} 
            name="convicted" 
            message="This is required." 
            as={
              <p 
                className="invalid-feedback"
                style={{display:"block"}}
              >
                This is required.
              </p>
            }
          />
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="Yes"
                name="convicted"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                Yes
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                innerRef={register({ required: true})}
                defaultValue="No"
                name="convicted"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                No
            </Label>
          </FormGroup>

          {/* */}
          <Label>If yes, please state the nature of the crime(s), when and where convicted and disposition of the case.</Label>
          <FormGroup>
            <Input
              className="text-area"
              onChange={handleInputChange}  
              placeholder="Please tell us the nature of the crimes."
              rows="4"
              type="textarea"
              name="convictionDescription"
            ></Input>
          </FormGroup>
          <p style={{fontSize: ".8em", marginTop: "1em"}}>
          No applicant will be denied employment solely on the grounds of conviction of a criminal offense. The date of the offense, the nature of the offense, including any significant details that affect the description of the event, and the surrounding circumstances and the relevance of the offense to the position(s) applied for may, however, be considered.
          </p>
          { position === "Office Position" 
            ? <OfficeQuestions handleFormEntry={handleFormEntry}/> 
            : (position === "Driver Position") 
              ? <DriverQuestions handleFormEntry={handleFormEntry}/> 
              : null}

          {/* Next and Previous Buttons */}
          <Row className="d-flex justify-content-center">
            <Col md="12" className="d-flex justify-content-between mb-5 pt-3">
              <Button
                color="info"
                id="previous"
                onClick={e => handleClick(null, e)}
              >Previous</Button>

              <Button
                color="info"
                id="next"
                onClick={handleSubmit(handleClick)}
              >Next
              </Button>
            </Col>
          </Row>
        </Form>
      </FormProvider>
  );
}

export default JobQuestionnaire;