import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Collapse,
  CardBody,
  Card
} from "reactstrap";

// core components
import DefaultNavBar from "components/Navbars/DefaultNavBar.js";
import EmploymentOpportunitiesHeader from "components/Headers/EmploymentOpportunities.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import JobAppForm from "components/Forms/JobAppForm/JobAppForm.js";
import driverPDF from "../../../assets/files/driver-app.pdf";
import officePDF from "../../../assets/files/office-app.pdf";

function EmploymentOpportunities(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <DefaultNavBar {...props} />
      <div className="wrapper jobs-wrapper">
        <EmploymentOpportunitiesHeader {...props} />
          <Container>
            <div className="section">
              <Row className="justify-content-center mb-4">
                <Col md="10" className="text-left">
                  <p>
                    As a respected industry leader, Unlimited Transportation is dedicated to finding the right people who share our core values and demonstrate a passion for what we do.
                    <br/>
                    <br/>
                    If this describes you, please print and submit the PDF, or fill out the application online.
                    <br/>
                    Submit resumes and/or applications via fax, or to:
                    <a 
                      style={{color:"#158FBF"}}
                      href="mailto:dispatch@unlimitedtransportation.com"
                    > dispatch@unlimitedtransportation.com
                    </a>
                    </p>
                  <div className="d-flex justify-content-left">
                  <a
                    className="btn btn-link"
                    href= { officePDF }
                    target="_blank"
                  >Office Application PDF
                  </a>
                  <a
                    className="btn btn-link"
                    href= { driverPDF }
                    target="_blank"
                  >Driver Application PDF
                  </a>
                  </div>
                  <Button 
                    size="lg"
                    className="btn btn-info mb-4 mt-4"
                    onClick={toggle} 
                  >
                    {!isOpen ? "Apply Online" : "Close Application"}
                  </Button>   
                </Col>
              </Row>
              <Row>
                <Col>
                  <Collapse isOpen={isOpen}>
                    <Card>
                      <CardBody>
                        <JobAppForm isOpen={isOpen} setIsOpen={setIsOpen} />
                      </CardBody>
                    </Card>
                  </Collapse>
                </Col>
              </Row>
            </div>
          </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default EmploymentOpportunities;
