import React, { useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ApplicantInfo from './components/ApplicantInfo.js';
import JobQuestionnaire from "./components/JobQuestionnaire.js";
import Education from "./components/Education.js";
import EmploymentHistory from "./components/EmploymentHistory.js";
import Review from "./components/Review.js";

function JobAppForm(props) {
  const { isOpen, setIsOpen } = props;
  const [iconPills, setIconPills] = React.useState("1");
  const initialFormState = {};

  const [formData, setFormData] = useState(initialFormState);

  const handleFormEntry = e => {
    return setFormData({...formData, [e.target.name]: e.target.value});
  }


  const updatePillButtons = (pill) => {
    const nextPill = String(pill);
    return setIconPills(nextPill);
  }

  return (
    <>
      <div className="section section-tabs">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" >
              <h1 className="text-center">Job Application</h1>
              <p className="text-justify pb-3" style={{fontSize:"1em"}}>
                Unlimited Transportation Corp is an equal opportunity employer. This application will not be used for limiting or excluding any applicant from consideration for employment on a basis prohibited by local, state, or federal law. Should an applicant need reasonable accommodation in the application process, he or she should contact a company representative.
              </p>
              <Card>
                <CardHeader>
                  <Nav className="justify-content-center mt-5 mb-3" role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        disabled
                        className={iconPills === "1" ? "active" : ""}
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          setIconPills("1");
                        }}
                      >Applicant Info
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        disabled
                        className={iconPills === "2" ? "active" : ""}
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          setIconPills("2");
                        }}
                      >Job Questionnaire
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        disabled
                        className={iconPills === "3" ? "active" : ""}
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          setIconPills("3");
                        }}
                      >Education and Training
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        disabled
                        className={iconPills === "4" ? "active" : ""}
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          setIconPills("4");
                        }}
                      >Employment History
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        disabled
                        className={iconPills === "5" ? "active" : ""}
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          setIconPills("5");
                        }}
                      >Sign and Submit
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>
                  <Row className="d-flex justify-content-center">
                    <Col md="8">
                      <TabContent
                      activeTab={"iconPills" + iconPills}
                      >
                        {/* Applicant Info */}
                        <TabPane tabId="iconPills1">
                          <ApplicantInfo 
                            handleFormEntry={handleFormEntry} 
                            updatePillButtons={updatePillButtons}
                          />
                        </TabPane>
                        {/* Job Questionnaire */}
                        <TabPane tabId="iconPills2">
                          <JobQuestionnaire 
                            formData={formData} 
                            handleFormEntry={handleFormEntry}
                            updatePillButtons={updatePillButtons}
                          />
                        </TabPane>
                        {/* Education and Training */}
                        <TabPane tabId="iconPills3">
                          <Education 
                            handleFormEntry={handleFormEntry}
                            updatePillButtons={updatePillButtons} 
                          />
                        </TabPane>
                        {/* Employment History */}
                        <TabPane tabId="iconPills4">
                          <EmploymentHistory 
                            handleFormEntry={handleFormEntry}
                            updatePillButtons={updatePillButtons}
                          />
                        </TabPane>
                        {/* Review and Send */}
                        <TabPane tabId="iconPills5">
                          <Review 
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            setFormData={setFormData}
                            formData={formData} 
                            handleFormEntry={handleFormEntry}
                            updatePillButtons={updatePillButtons}
                          />
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default JobAppForm;
