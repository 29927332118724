import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import OurStory from './OurStory.js';
import QuoteCarousel from '../../../components/Carousels/QuoteCarousel.js';


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


function HomeAbout( { formData } ) {
  const { width: windowWidth } = useWindowDimensions();
  const imagesQuotes =
  <>
    <Container>
      <div className="section-story-overview">
        <Row>
          <Col md="6">
            <div
              className="image-container image-left"
              style={{
                backgroundImage: "url(" + require("assets/img/newtruck_2.jpg") + ")"
              }}
            >
            </div>
            </Col>
            <Col md="6">
              <div
                className="image-container image-right"
                style={{
                  backgroundImage: "url(" + require("assets/img/services/IMG_1751.jpg") + ")"
                }}
              >
              </div>
            </Col>
          </Row>
        </div>
    </Container>
    <QuoteCarousel />
  </>;

  return (
    <>
      <Container className="mt-0">
        <Row>
          <Col className="ml-auto mr-auto text-center" md="10">
            <h2 className="title">Who are we?</h2>
            <p className="lead">
            Our success is attributed to our commitment to the customer first and foremost.
            <br/>
            Unlimited Transportation is driven to be the very best in the logistics industry and we look forward to handling your specific needs.
          </p>
          </Col>
        </Row>
      </Container>
      { windowWidth > 768 ? imagesQuotes : null }
      <Container>
        <OurStory />
      </Container>
    </>
  );
}

export default HomeAbout;
