import React from 'react';

import {
    TabPane,
    Row,
    Col,
  } from "reactstrap";

function MedicalTab() {
  return(
    <TabPane tabId="pills4">
      <Col className="ml-auto mr-auto" md="10">
        <Row className="collections justify-content-center">
          <Col md="8">
              <h4 className="text-justify">
              Your shipment needs to deliver to a hospital or medical facility.
              We specialize in medical deliveries, including unpack and debris removal as well as inside deliveries.
              Our drivers have been trained to handle medical device deliveries and will give each shipment
              the special attention that each one deserves. We are professional, accommodating and strive to
               ensure that all your needs and your customer’s needs are met.
            </h4>
          </Col>
        </Row>

        <Row className="collections justify-content-center">
          <Col md="8">
            <img
              alt="Medical Deliveries"
              className="img-raised mt-5 w-100"
              src={require("assets/img/services/medicalDeliveries.jpg")}
            />
          </Col>
        </Row>
      </Col>
    </TabPane>
  );
};

export default MedicalTab;