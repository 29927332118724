import React from "react";

function ServicesHeader() {

  return (
    <>
      <div
        className="page-header clear-filter page-header-small services-header"
        filter-color="blue"
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/blurry-highway.png") + ")"
          }}
        ></div>
        <div className="content-left">
          <h1 className="services-title mb-0">Shipping Services and Specialty Freight.</h1>
          <p className="text-left">Once your freight leaves the loading dock, it’s out of your hands,
              but not out of your head. When you trust us with the on-time delivery of your freight,
              we fret the big and small stuff so you don’t have to.
            </p>
        </div>
      </div>
    </>
  );
}

export default ServicesHeader;
