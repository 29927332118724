import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";


function OurStory() {

  return (
    <Container>
      <Row>
        <Col className="ml-auto mr-auto text-justify" md="8">
          <h3>
            It’s our privilege to have earned the trust of innumerable clients through the years. From the smallest parcel to the largest shipment, we’ve treated each job as the most important one.
          </h3>
          <p>
            Unlimited Transportation was founded in January of 1999 with a focus on providing the freight community with an amazing customer experience. Prioritizing this focus, we have developed a business model centered on the most important component of the industry – the customer.
            <br/>
            <br/>
            We are a dedicated team of logistics experts who have left behind the outdated shipping methods of the previous century. We ship the unshippable – and deliver it the way you want. We’ve built our reputation on excellence in shipping and logistics, with a constant focus on serving our customers precisely and effeciently.
            <br/>
            <br/>
            We are focused on being your logistics partner to develop a tailored strategy with you for any and all of your shipping needs. Please contact us to learn more about our solutions and receive a quote.
          </p>
        </Col>
      </Row>
  </Container>
  );
}

export default OurStory;
