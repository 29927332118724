import React from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import {
  Input,
  Form,
  Col,
  Row,
  FormGroup,
  Label,
  FormFeedback,
  Button
  } from "reactstrap";

function ApplicantInfo(props) {
  const { handleFormEntry, updatePillButtons } = props;

  const handleInputChange = e => {
    handleFormEntry(e)
  }

  const { register, errors, handleSubmit } = useForm({
    mode: "all",
  });

  const handleClick = async (data, e) => {
    let pill = 1;
    if (e.target.id === "next") {
      pill++
      return updatePillButtons(pill)
    }
  }



    return(
      <Form>
        <p className="lead mb-4">Applicant Info</p>
        {/* */}
        <Label>What position are you applying for?*</Label>
        <ErrorMessage 
            errors={errors} 
            name="position" 
            message="This is required." 
            as={
              <p 
                className="invalid-feedback"
                style={{display:"block"}}
              >
                This is required.
              </p>
            }
          />
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                className={errors.position && "is-invalid"}
                innerRef={register({ required: true})}
                defaultValue="Office Position"
                name="position"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                Office Position
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                className={errors.position && "is-invalid"}
                innerRef={register({ required: true})}
                defaultValue="Driver Position"
                name="position"
                type="radio"
                onChange={handleInputChange}
              ></Input>
                <span className="form-check-sign"></span>
                Driver Position
            </Label>
          </FormGroup>

          {/* */}
          <FormGroup>
            <Label>Full Name*</Label>
              <Input
                className={errors.fullName && "is-invalid"}
                name="fullName"
                placeholder="Full Name"
                type="text"
                onChange={handleInputChange}
                innerRef={register({ required: true})}
              ></Input>
            <FormFeedback>This is required.</FormFeedback>
           </FormGroup>

          {/*  */}
          <FormGroup>
            <Label>Street Address*</Label>
            <Input
              className={errors.streetAddress && "is-invalid"}
              name="streetAddress"
              placeholder="Street Address"
              type="text"
              onChange={handleInputChange}
              innerRef={register({ required: true })}
            ></Input>
            <FormFeedback>This is required.</FormFeedback>
          </FormGroup>

          {/* */}
          <Row>
            <Col xl="6">
              <Label>City, State*</Label>
              <FormGroup>
                <Input
                  className={errors.cityState && "is-invalid"}
                  name="cityState"
                  placeholder="City, State"
                  type="text"
                  onChange={handleInputChange}
                  innerRef={register({ required: true})}
                ></Input>
              <FormFeedback>This is required.</FormFeedback>
            </FormGroup>              
            </Col>

            {/* */}
            <Col xl="6">
              <Label>Zip Code*</Label>
              <FormGroup>
                <Input
                  className={errors.zipCode && "is-invalid"}
                  name="zipCode"
                  placeholder="Zip Code"
                  type="text"
                  onChange={handleInputChange}
                  innerRef={register({ required: true})}
                ></Input>
                <FormFeedback>This is required.</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          {/*  */}
          <Label>Email*</Label>
          <FormGroup>
            <Input
              className={errors.email && "is-invalid"}
              name="email"
              placeholder="Email"
              type="email"
              onChange={handleInputChange}
              innerRef={register({ required: true})}
            ></Input>
            <FormFeedback>This is required.</FormFeedback>
          </FormGroup>


          {/* */}
          <Label>Phone*</Label>
          <FormGroup>
            <Input
              className={errors.phone && "is-invalid"}
              name="phone"
              placeholder="Phone"
              type="tel"
              onChange={handleInputChange}
              innerRef={register({ required: true})}
            ></Input>
            <FormFeedback>This is required.</FormFeedback>
          </FormGroup>
          
          {/* Next and Previous Buttons */}
          <Row className="d-flex justify-content-center">
            <Col md="12" className="d-flex justify-content-between mb-5 pt-3">
              <Button
                disabled
                className="disabled"
                color="info"
                id="previous"
              >Previous</Button>
              <Button
                color="info"
                id="next"
                onClick={handleSubmit(handleClick)}
              >Next
              </Button>
            </Col>
          </Row>
        </Form>
  );
}

export default ApplicantInfo;