import React, { useState } from 'react';

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators
} from 'reactstrap';

const items = [
  {
    id: 1,
    source: "KYLE, RADIANT GLOBAL LOGISTICS",
    quote: "They were very helpful when I called late after hours to set up a next day run and my customer was also impressed with the driver’s professionalism and accommodating manner."
  },
  {
    id: 2,
    source: 'Derek Luce, Executive Liason, Direct & Beyond, Inc.',
    quote: 'I just wanted to mention to you what a great job Steph does for us all of the time. She owns her work, is prompt on her response times, communicates very well – in fact is pleasant to speak to – and we know that we can rely upon her to take care of us.'
  },
  {
    id: 3,
    source: 'Kyle, Operations, Forward Air',
    quote: 'I was especially happy with the service I received from the CMH terminal. They were very helpful when I called late after hours to set up a next day run and my customer was also impressed with the driver’s professionalism and accommodating manner.'
  }
];

const QuoteCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className="custom-tag"
        tag="div"
        key={item.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <blockquote className="blockquote mb-0">
          <p>
            {item.quote}
          </p>
          <footer className="blockquote-footer">
            <cite title="Source Title">{item.source}</cite>
          </footer>
        </blockquote>
      </CarouselItem>
    );
  });

  return (
    <div>
      <Carousel
        interval={false}
        autoPlay={false}
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
    </div>
  );
}

export default QuoteCarousel;