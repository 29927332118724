import React, { useState } from 'react';
import { useForm } from 'react-hook-form'

import {
  Button,
  Input,
  Form,
  FormFeedback,
  FormGroup,
  Label
  } from "reactstrap";

function ContactForm(props) {
  const initialInputState = {};

  const [eachEntry, setEachEntry] = useState(initialInputState);

  const handleInputChange = e => {
    return setEachEntry({...eachEntry, [e.target.name]: e.target.value});
  }

  const { register, handleSubmit, errors, reset } = useForm();
  const [validated, setValidation] = useState(false);
  const onSubmit = async (data) => {
    const url = 'https://hooks.zapier.com/hooks/catch/8871696/olux56o/';
    const method = 'POST';
    const body = JSON.stringify(data);

    // console.log('Have request to send to Zapier: ', eachEntry);

    let res;
    try {
      res = await fetch(url, { method, body });
    } catch(err) {
      console.log('Error sending request to Zapier: ', err);
      setValidation(false)
      return;
    }

    if (res.status !== 200) {
      console.log('Error sending request to Zapier: ', res);
      setValidation(false);
      return;
    }

    reset()
    return setValidation(true);
  }

    return(
        <Form className="justify-content-center text-left needs-validation" id="general-contact-form">
          {/* Name Input */}
          <Label>Full Name*</Label>
          <FormGroup>
            <Input
              className={errors.fullName && "is-invalid"}
              innerRef={register({ required: true})}
              placeholder="Full Name"
              type="text"
              name="fullName"
              onChange={handleInputChange}
            ></Input>
            {errors.fullName && <FormFeedback>This is required.</FormFeedback>}
          </FormGroup>

          {/* Company Input */}
          <Label>Company</Label>
          <FormGroup>
            <Input
              innerRef={register}
              placeholder="Company"
              type="text"
              name="company"
              onChange={handleInputChange}
            ></Input>
          </FormGroup>

          {/* Email Input */}
          <Label>Email*</Label>
          <FormGroup>
            <Input
              className={errors.email && "is-invalid"}
              innerRef={register({ required: true})}
              placeholder="Email"
              type="email"
              name="email"
              onChange={handleInputChange}
            ></Input>
            {errors.email && <FormFeedback>This is required.</FormFeedback>}
          </FormGroup>

          {/* Phone Input */}
          <Label>Phone*</Label>
          <FormGroup>
            <Input
              className={errors.phone && "is-invalid"}
              innerRef={register({ required: true})}
              placeholder="Phone"
              type="tel"
              name="phone"
              onChange={handleInputChange}
            ></Input>
            {errors.phone && <FormFeedback>This is required.</FormFeedback>}          
          </FormGroup>

          {/* Message Input */}
          <Label>Message*</Label>
          <FormGroup>
            <Input
              // className={errors.message && "is-invalid"}
              innerRef={register({ required: true})}
              name="message"
              className={"text-area " + (errors.message && 'is-invalid')}
              onChange={handleInputChange}  
              placeholder="Type a message..."
              rows="4"
              type="textarea"
            ></Input>
            {errors.message && <FormFeedback>This is required.</FormFeedback>}
          </FormGroup>
          
          {/* Submit Button */}
          <div className="text-center">
            <Button
              className="btn-square"
              color="info"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              size="md"
            >
              Send Message
            </Button>
          </div>
          {validated && <p className="text-center pt-4 category">Form Submitted Successfully! We'll be in touch.</p>}
        </Form>
  );
}

export default ContactForm;