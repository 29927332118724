import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


function HomeServices(props) {
  const { width: windowWidth } = useWindowDimensions();

  function pushRoute(e, route) {
    e.preventDefault();
    props.history.push(route);
  }

  return (
    <div className="section section-services pt-5 pb-2">
      <Container>
        <div className="">
          <h2 className="text-center">Services</h2>
          <div className="services-wrapper d-flex text-center justify-content-center flex-wrap w-100">
            <Row className={"align-items-start " + (windowWidth > 600 ? "w-50" : "")}>
              <Col>
                <div className="service-icon">
                  <Button
                    className="btn-icon btn-square"
                    color="info"
                    href="/services#white-glove"
                    onClick={e => pushRoute(e, '/services#white-glove')}
                  >
                    <i className="fas fa-hands"></i>
                  </Button>
                  <h3>White Glove</h3>
                </div>
              </Col>
              <Col>
                <div className="service-icon">
                  <Button
                    className="btn-icon btn-square"
                    color="info"
                    href="/services#medical"
                    onClick={e => pushRoute(e, '/services#medical')}
                  >
                    <i className="fas fa-first-aid"></i>
                  </Button>
                  <h3>Medical Deliveries</h3>
                </div>
              </Col>
              </Row>
              <Row className={"align-items-start " + (windowWidth > 600 ? "w-50" : "")}>
              <Col>
                <div className="service-icon">
                  <Button
                    className="btn-icon btn-square"
                    color="info"
                    href="/services#warehousing"
                    onClick={e => pushRoute(e, '/services#warehousing')}
                  >
                    <i className="fas fa-warehouse"></i>
                  </Button>
                  <h3>Warehousing</h3>
                </div>
              </Col>
              <Col>
                <div className="service-icon">
                  <Button
                    className="btn-icon btn-square"
                    color="info"
                    href="/services#special-deliveries"
                    onClick={e => pushRoute(e, '/services#special-deliveries')}
                  >
                    <i className="fas fa-box"></i>
                  </Button>
                  <h3>Special Deliveries</h3>
                </div>
              </Col>
            </Row>
          </div>
        </div>
    </Container>
  </div>
  );
}

export default HomeServices;
