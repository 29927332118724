import React from 'react';

import {
    TabPane,
    Row,
    Col,
  } from "reactstrap";

function WhiteGloveTab() {
  return(
    <TabPane tabId="pills3">
      <Col className="ml-auto mr-auto" md="10">
        <Row className="collections justify-content-center">
          <Col md="8">
            <h4 className="text-justify">
              Unlimited Transportation does not just handle the day to day, dock to dock deliveries.
              Your customer is high profile and wants their shipment taken inside to the 3rd floor,
              unpacked and trash disposed of? We can handle it. We also do asset recovery. Tell us all your
              specific needs and we will follow through beginning to end.
            </h4>
          </Col>
        </Row>

        <Row className="collections justify-content-center">
          <Col className="justify-content-center" md="8">
            <img
              alt="White Glove"
              className="img-raised mt-5 w-100"
              src={require("assets/img/services/whiteGlove.jpg")}
            />
          </Col>
        </Row>
      </Col>
    </TabPane>
  );
};

export default WhiteGloveTab;