/*

=========================================================
* Now UI Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/master/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ReactGA from 'react-ga';

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/nucleo-icons-page-styles.css";

// pages for this kit
import HomePage from "views/pages/Home";
import ServicesPage from "views/pages/Services";
import AboutUsPage from "views/pages/AboutUs";
import ContactUsPage from "views/pages/ContactUs";
import EmploymentOpportunitiesPage from "views/pages/EmploymentOpportunities";

ReactGA.initialize('UA-83446234-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route exact path="/" render={props => <HomePage {...props} />} />
        <Route exact path="/services" render={props => <ServicesPage {...props} />} />
        <Route exact path="/about" render={props => <AboutUsPage {...props} />} />
        <Route exact path="/contact-us" render={props => <ContactUsPage {...props} />} />
        <Route exact path="/jobs" render={props => <EmploymentOpportunitiesPage {...props} />} />
        <Redirect to="/" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
