import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import {
  Input,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
  } from "reactstrap";

function Review(props) {
  const { formData: {fullName}, handleFormEntry, formData, updatePillButtons, setFormData, isOpen, setIsOpen } = props;

  const [formSubmitted, setFormSubmitted ] = useState(false);

  const handleInputChange = e => {
    handleFormEntry(e)
  }

  const { register, errors, handleSubmit, reset } = useForm({
    mode: "all"
  });

  const handleClick = async (data, e) => {
    let pill = 5;
    if (e.target.id === "previous") {
      pill--
      updatePillButtons(pill)
    }
  }

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const onSubmit = async (data, e) => {
  
    const url = 'https://hooks.zapier.com/hooks/catch/8871696/olunsoq/';
    const method = 'POST';
    const body = JSON.stringify(formData);

    // console.log('Have request to send to Zapier: ', eachEntry);

    let res;
    try {
      res = await fetch(url, { method, body });
    } catch(err) {
      console.log('Error sending request to Zapier: ', err);
      return;
    }

    if (res.status !== 200) {
      console.log('Error sending request to Zapier: ', res);
      return;
    }
    setIsOpen(!isOpen);
    reset();
    setFormData({});
    return setFormSubmitted(true)
  }

  const todayDate = moment().format('YYYY-MM-DD');

  return(
    <div>
      <p className="justify-center" style={{fontSize:"1em"}}>
        AT-WILL EMPLOYMENT.
        <br/>
        The relationship between you and the Unlimited Transportation Corp is referred to as "employment at will." This means that your employment can be terminated at any time for any reason, with or without cause, with or without notice, by you or the Unlimited Transportation Corp. No representative of Unlimited Transportation Corp has authority to enter into any agreement contrary to the foregoing "employment at will" relationship. You understand that your employment is "at will," and that you acknowledge that no oral or written statements or representations regarding your employment can alter your at-will employment status, except for a written statement signed by you and either our Executive Vice-President/Chief Operations Officer or the Company's President.
        <br/>
        <br/>
        By typing my name below, I {fullName ? `, ${fullName},` : null}certify that the above statements are true and correct to the best of my knowledge. I understand and agree that this form of electronic signature has the same legal force and affect as a manual signature. 
      </p>
      <Form>
        <Row>
          <Col xl="6">
            {/* */}
            <Label>Signature*</Label>
              <FormGroup>
                <Input
                  className={errors.signature && "is-invalid"}
                  innerRef={register({ required: true})}
                  name="signature"
                  placeholder="Signature"
                  type="text"
                  onChange={handleInputChange}
                ></Input>
                <FormFeedback>This is required.</FormFeedback>
              </FormGroup>
            </Col>

            <Col xl="6">
              {/* */}
              <FormGroup>
                <Label>Date*</Label>
                  <Input
                    value={todayDate}
                    min={todayDate}
                    max={todayDate}
                    className={errors.applicationDate && "is-invalid"}
                    innerRef={register({ required: true})}
                    type="date"
                    name="applicationDate"
                    onChange={handleInputChange}
                    placeholder="Application Date"
                  />
                  <FormFeedback>This is required.</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          
          {/* Submit Button */}
          <div className="text-center mt-3">
            <Button
              disabled={ formSubmitted ? true : false }
              className="btn-square"
              color="info"
              style={{marginBottom: "100px"}}
              onClick={handleSubmit(toggle)}
              size="lg"
            >
              Submit Application
            </Button>
          </div>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>{!formSubmitted ? "Job Application" : "Submission Successful!" }</ModalHeader>
             { !formSubmitted ? 
                <ModalBody>
                  Are you sure? Please check to ensure that all information has been filled out correctly. 
                </ModalBody>
                :
                <ModalBody>
                  You're job application was submitted successfully! We'll be in touch if we think it's a good fit.
                </ModalBody>
              }
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>{ !formSubmitted ? "No, Cancel" : "Close" }</Button>
              { !formSubmitted ? <Button color="info" onClick={onSubmit}>Yes, Submit</Button>: null }
            </ModalFooter>
          </Modal>

          {/* Next and Previous Buttons */}
          <Row className="d-flex justify-content-center">
            <Col md="12" className="d-flex justify-content-between mb-5 pt-3">
              <Button
                disabled={ formSubmitted ? true : false }
                color="info"
                id="previous"
                onClick={e => handleClick(null, e)}
              >Previous</Button>
              <Button
                disabled
                className="disabled"
                color="info"
                id="next"
              >Next
              </Button>
            </Col>
          </Row>
      </Form>
    </div>
  );
}

export default Review;