import React from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import {
  Input,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Button
  } from "reactstrap";

function Education(props) {
  const { handleFormEntry, updatePillButtons } = props;

  const handleInputChange = e => {
    handleFormEntry(e)
  }

  const { register, errors, handleSubmit } = useForm({
    mode: "all",
  });

  const handleClick = async (data, e) => {
    let pill = 3;
    if (e.target.id === "next") {
      pill++
      updatePillButtons(pill)
    } else if (e.target.id === "previous") {
      pill--
      updatePillButtons(pill)
    }
  }

    return(
      <Form>
        <p className="lead">Education and Training</p>
        <p className="mt-5 mb-n1 category">High School</p>

        {/* */}
        <FormGroup>
        <Label>Name</Label>
          <Input
            name="highSchoolName"
            placeholder="High School Name"
            type="text"
            onChange={handleInputChange}
          ></Input>
        </FormGroup>

        {/* */}
        <FormGroup>
        <Label>Location</Label>
          <Input
            name="highSchoolLocation"
            placeholder="Location (City, State)"
            type="text"
            onChange={handleInputChange}            
          ></Input>
        </FormGroup>

        {/**/}
        <FormGroup>
        <Label>Year Graduated</Label>
          <Input
            name="yearGraduatedHS"
            placeholder="Year Graduated"
            type="number"              
            onChange={handleInputChange}
          ></Input>
        </FormGroup>    

        <p className="mt-5 mb-n1 category">College/University</p>
        {/* */}
        <FormGroup>
        <Label>Name</Label>
          <Input
            name="collegeName"
            placeholder="College Name"
            type="text"
            onChange={handleInputChange}
          ></Input>
        </FormGroup>

        {/* */}
        <FormGroup>
        <Label>Location</Label>
          <Input
            name="collegeLocation"
            placeholder="Location (City, State)"
            type="text"
            onChange={handleInputChange}
          ></Input>
        </FormGroup>

        {/* */}
        <Label>Year Graduated</Label>
        <FormGroup>
          <Input
            name="yearGraduatedCollege"
            placeholder="Year Graduated"
            type="text"
            onChange={handleInputChange}
          ></Input>
        </FormGroup> 

        {/* */}
        <Label>Degree Earned</Label>
        <FormGroup>
          <Input
            name="collegeDegreeEarned"
            placeholder="Degree Earned"
            type="text"
            onChange={handleInputChange}              
          ></Input>
        </FormGroup>


        <p className="mt-5 mb-n1 category">Vocational School/Specialized Training</p>
        {/* */}
        <Label>Name</Label>
        <FormGroup>
          <Input
            name="vocationSchoolName"
            placeholder="Name"
            type="text"
            onChange={handleInputChange}
          ></Input>
        </FormGroup>

        {/* */}
        <Label>Location</Label>
        <FormGroup>
          <Input
            name="vocationSchoolLocation"
            placeholder="Location (City, State)"
            type="text"
            onChange={handleInputChange}
          ></Input>
        </FormGroup>

        {/* */}
        <Label>Year Graduated</Label>
        <FormGroup>
          <Input
            name="graduatedVocationSchool"
            placeholder="Year Graduated"
            type="text"
            onChange={handleInputChange}
          ></Input>
        </FormGroup>  

        {/* */}
        <Label>Degree Earned</Label>
        <FormGroup>
          <Input
            name="vocationDegree"
            placeholder="Degree Earned"
            type="text"
            onChange={handleInputChange}
          ></Input>
        </FormGroup>


        {/* */}
        <p className="mt-5 mb-n1 category">Military</p>
        <Label>Are you a member of the Armed Services?*</Label>
        <ErrorMessage 
            errors={errors} 
            name="armedServices" 
            message="This is required." 
            as={
              <p 
                className="invalid-feedback"
                style={{display:"block"}}
              >
                This is required.
              </p>
            }
          />
        <FormGroup check className="form-check-radio">
          <Label check>
            <Input
              innerRef={register({ required: true})}
              defaultValue="Yes"
              name="armedServices"
              type="radio"
              onChange={handleInputChange}
            ></Input>
              <span className="form-check-sign"></span>
              Yes
          </Label>
        </FormGroup>
        <FormGroup check className="form-check-radio">
          <Label check>
            <Input
              innerRef={register({ required: true})}
              defaultValue="No"
              name="armedServices"
              type="radio"
              onChange={handleInputChange}
            ></Input>
              <span className="form-check-sign"></span>
              No
          </Label>
        </FormGroup>

        {/* */}
        <Label>What branch of the military did you enlist?</Label>
        <FormGroup>
          <Input
            name="militaryBranch"
            placeholder="Military Branch"
            type="text"
            onChange={handleInputChange}
          ></Input>
        </FormGroup>

        {/* */}
        <Label>What was your military rank when discharged?</Label>
        <FormGroup>
          <Input
            name="militaryRank"
            placeholder="Military Rank"
            type="text"
            onChange={handleInputChange}
          ></Input>
        </FormGroup>

        {/* */}
        <Label>How many years did you serve in the military?</Label>
        <FormGroup>
          <Input
            name="yearsServed"
            placeholder="Years Served"
            type="text"
            onChange={handleInputChange}
          ></Input>
        </FormGroup>
          
        {/* */}
        <Label>What military skills do you possess that would be an asset for this position?</Label>
        <FormGroup>
          <Input
            name="militarySkills"
            className="text-area"
            onChange={handleInputChange}  
            placeholder="Please list applicable military skills."
            rows="4"
            type="textarea"
          ></Input>
        </FormGroup>

        {/* Next and Previous Buttons */}
        <Row className="d-flex justify-content-center">
          <Col md="12" className="d-flex justify-content-between mb-5 pt-3">
            <Button
              color="info"
              id="previous"
              onClick={e => handleClick(null, e)}
            >Previous</Button>
            <Button
              color="info"
              id="next"
              onClick={handleSubmit(handleClick)}
            >Next
            </Button>
          </Col>
        </Row>
      </Form>
  );
}

export default Education;