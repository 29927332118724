
import React from "react";

function EmploymentOpportunitiesHeader (props) {

  return (
    <>
      <div className="page-header page-header-small jobs-header">
        <div
          className="page-header-image"
          style={{
            backgroundImage: 'url('+require('assets/img/services/DSC00076.JPG')+')',
            backgroundRepeat: 'no-repeat',
            backgroundPositionY: '50%',
            backgroundColor: 'transparent',
          }}
        >
          <div
            className="image-overlay"
            style={{
                margin: '0px 0px',
                padding: 0,
                height: '120%',
                position: 'relative',
                background: 'linear-gradient(-45deg, transparent 36%, #222559 36%)',
                opacity: .9,
            }}
          >
            <div className="content-left">
              <h1 className="jobs-title">Job Opportunities.</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EmploymentOpportunitiesHeader;