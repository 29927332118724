import React, { Component } from "react";

import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const mapStyles = {
    width: "100%",
    height: "100%",
};

const coordinates = {
    lat: 39.99695991532493,
    lng: -83.13579707737769,
};

class MapContainer extends Component {
    render() {
        return (
            <Map
                google={this.props.google}
                zoom={15}
                style={mapStyles}
                initialCenter={coordinates}
            >
                <Marker
                    title="Unlimited Transportation"
                    name="Unlimited Transportation"
                    position={coordinates}
                />
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyCURH0B4gwhOjVNfVbUXk7Hte4XmEStvXo",
})(MapContainer);
