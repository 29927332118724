import React, { useState } from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";

// core components
import DefaultNavBar from "components/Navbars/DefaultNavBar.js";
import HomeHeader from "components/Headers/HomeHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import ContactForm from "components/Forms/ContactForm.js";
import HomeServices from "./HomeServices";
import HomeAbout from "./HomeAbout";

function HomePage(props) {
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");

    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <DefaultNavBar {...props} />
      <div className="wrapper home-wrapper">

      <Alert className="position-fixed w-100" color="info" isOpen={visible} toggle={onDismiss}>
      Our commitment to serving our customers has never been stronger and Unlimited Transportation will continue to follow all safety measures and protocols to maximize the safety of all employees as well as our customers.  Unlimited Transportation will continue to work diligently to provide all services our customers expect while staying focused on keeping everyone healthy and safe during the COVID-19 pandemic.
      </Alert>
      
      <HomeHeader {...props} />
        <HomeServices {...props} />
        <div className="section section-about-us pb-3">
          <HomeAbout />
        </div>
        <Container className="section text-center" style={{width: "80%"}}>
          <Row className="justify-content-center">
            <Col lg="5" md="10" sm="12">
              <h2 className="title">Let Us Work For You</h2>
              <p className="description">Give us a call or drop us a line. 24 hours a day, 365 days a year.</p>
            <ContactForm />
            </Col>
          </Row>
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default HomePage;