import React from "react";


function HomeHeader(props) {
  
  return (
    <>
      <div className="page-header page-header-small home-header">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/straight-truck-edited.png") + ")",
            backgroundRepeat: 'no-repeat',
          }}
        ></div>
        <div className="content-left">
          <h1 className="home-title">We care about your freight.</h1>
        </div>
      </div>
    </>
  );
}

export default HomeHeader;