import React, { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function ContactUsHeader() {

  const { width: windowWidth } = useWindowDimensions();

  return (
    <>
      <div className="page-header page-header-small contact-header">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/chuttersnap-BNBA1h-NgdY-unsplash.jpg") + ")",
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'transparent',
          }}
        >
          <div
            className="image-overlay"
            style={{
                margin: '0px 0px',
                padding: 0,
                height: '120%',
                position: 'relative',
                top: '-20%',
                background: 'linear-gradient(-45deg, transparent 36%, #222559 36%)',
                opacity: .9,
            }}
          >
            <div className="content-left">
              <h1 className="title mb-0">
                Let us work for you.
              </h1>
              {windowWidth > 768 ?
                <>
                  <p>
                    Give us a call or drop us a line. 24 hours a day, 365 days a year – We are your solution to anything you need expedited from point A to point B.
                  </p>
                </> 
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUsHeader;