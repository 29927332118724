import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';


import {
  Input,
  FormGroup,
  Label,
  FormFeedback
  } from "reactstrap";

function DriverQuestions(props) {
  const { handleFormEntry } = props;

  const handleInputChange = e => {
    handleFormEntry(e)
  }

  const { register, errors } = useFormContext();

  return(
    <>
      <p style={{fontSize: ".8em"}}>
        Unlimited Transportation Corp complies with the ADA and considers reasonable accommodation measures that may be necessary for eligible applicants/employees to perform essential functions. It is possible that a hire may be tested on skill/agility and may be subject to a medical examination conducted by a medical professional. 
      </p>
      <Label>Do you have previous driving experience?*</Label>
      <ErrorMessage 
        errors={errors} 
        name="prevDrivingExp" 
        message="This is required." 
        as={
          <p 
            className="invalid-feedback"
            style={{display:"block"}}
          >
            This is required.
          </p>
        }
      />
        <FormGroup check className="form-check-radio">
          <Label check>
            <Input
              innerRef={register({ required: true})}
              defaultValue="Yes"
              name="prevDrivingExp"
              type="radio"
              onChange={handleInputChange}
            ></Input>
              <span className="form-check-sign"></span>
              Yes
          </Label>
        </FormGroup>
        <FormGroup check className="form-check-radio">
          <Label check>
            <Input
              innerRef={register({ required: true})}
              defaultValue="No"
              name="prevDrivingExp"
              type="radio"
              onChange={handleInputChange}
            ></Input>
              <span className="form-check-sign"></span>
              No
          </Label>
        </FormGroup>

        {/* */}
        <FormGroup>
        <Label>What equipment and how many years?*</Label>
          <Input
            className={errors.yrsEquipment && "is-invalid"}
            innerRef={register({ required: true})}
            name="yrsEquipment"
            placeholder="Equipment and years"
            type="text"
            onChange={handleInputChange}
          ></Input>
        <FormFeedback>This is required.</FormFeedback>          
        </FormGroup>

        <Label>Do you have an accident record?*</Label>
        <ErrorMessage 
          errors={errors} 
          name="accidentRecord" 
          message="This is required." 
          as={
            <p 
              className="invalid-feedback"
              style={{display:"block"}}
            >
              This is required.
            </p>
          }
        />
        <FormGroup check className="form-check-radio">
          <Label check>
            <Input
              innerRef={register({ required: true})}
              defaultValue="Yes"
              name="accidentRecord"
              type="radio"
              onChange={handleInputChange}
            ></Input>
              <span className="form-check-sign"></span>
              Yes
          </Label>
        </FormGroup>
        <FormGroup check className="form-check-radio">
          <Label check>
            <Input
              innerRef={register({ required: true})}
              defaultValue="No"
              name="accidentRecord"
              type="radio"
              onChange={handleInputChange}
            ></Input>
              <span className="form-check-sign"></span>
              No
          </Label>
        </FormGroup>

        {/* */}
        <Label>If yes, please state dates, nature of accident, fatalities or injuries. </Label>
        <FormGroup>
          <Input
            className="text-area"
            name="accidentRecordInfo"
            placeholder="Accident record information"
            type="textarea"
            rows="4"
            onChange={handleInputChange}
          ></Input>
        </FormGroup>

        <Label>Do you have any traffic convictions?*</Label>
        <ErrorMessage 
          innerRef={register({ required: true})}
          errors={errors} 
          name="trafficConvictions" 
          message="This is required." 
          as={
            <p 
              className="invalid-feedback"
              style={{display:"block"}}
            >
              This is required.
            </p>
          }
        />
        <FormGroup check className="form-check-radio">
          <Label check>
            <Input
              innerRef={register({ required: true})}
              defaultValue="Yes"
              name="trafficConvictions"
              type="radio"
              onChange={handleInputChange}
            ></Input>
              <span className="form-check-sign"></span>
              Yes
          </Label>
        </FormGroup>
        <FormGroup check className="form-check-radio">
          <Label check>
            <Input
              innerRef={register({ required: true})}
              defaultValue="No"
              name="trafficConvictions"
              type="radio"
              onChange={handleInputChange}
            ></Input>
              <span className="form-check-sign"></span>
              No
          </Label>
        </FormGroup>

        {/* */}
        <Label>If yes, please state location, date, and charge penalty.</Label>
        <FormGroup>
          <Input
            className="text-area"
            rows="4"
            type="textarea"
            name="trafficConvictionInfo"
            placeholder="Conviction Information"
            onChange={handleInputChange}
          ></Input>
        </FormGroup>

        {/* */}
        <Label>Driver License #, State, Type, Expiration Date*</Label>
        <FormGroup>
          <Input
            className={errors.licenseInfo && "is-invalid"}
            name="licenseInfo"
            placeholder="Driver's License Information"
            type="text"
            onChange={handleInputChange}
            innerRef={register({ required: true})}
          ></Input>
          <FormFeedback>This is required.</FormFeedback>
        </FormGroup>
    </>
  );
}

export default DriverQuestions;