import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import {
  Button,
  Input,
  FormGroup,
  Form,
  Label,
  FormFeedback
  } from "reactstrap";

function QuoteForm() {
  const initialInputState = {};
  
  const [eachEntry, setEachEntry] = useState(initialInputState);

  const handleInputChange = e => {
    return setEachEntry({...eachEntry, [e.target.name]: e.target.value});
  }

  const { register, handleSubmit, errors, reset } = useForm();
  const [validated, setValidation] = useState(false);
  
  const onSubmit = async (data) => {
    const url = 'https://hooks.zapier.com/hooks/catch/8871696/oldr2ul/';
    const method = 'POST';
    const body = JSON.stringify(data);

    // console.log('Have request to send to Zapier: ', eachEntry);

    let res;
    try {
      res = await fetch(url, { method, body });
    } catch(err) {
      console.log('Error sending request to Zapier: ', err);
      setValidation(false)
      return;
    }

    if (res.status !== 200) {
      console.log('Error sending request to Zapier: ', res);
      setValidation(false);
      return;
    }

    reset()
    return setValidation(true);
  }

    const todayDate = moment().format('YYYY-MM-DD');
    

    return(

      <Form className="justify-content-center text-left">

        {/* Name */}
        <FormGroup>
          <Label>Name*</Label>
          <Input
            className={errors.name && "is-invalid"}
            name="name"
            placeholder="Name"
            type="text"
            onChange={handleInputChange}
            innerRef={register({ required: true })}
          ></Input>
          {errors.name && <FormFeedback>This is required.</FormFeedback>}
        </FormGroup>

        {/* Shipper Input */}
        <FormGroup>
          <Label>Email*</Label>
          <Input
            className={errors.email && "is-invalid"}
            name="email"
            placeholder="Email"
            type="text"
            onChange={handleInputChange}
            innerRef={register({ required: true })}
          ></Input>
          {errors.email && <FormFeedback>This is required.</FormFeedback>}
        </FormGroup>

        {/* Shipper Input */}
        <FormGroup>
          <Label>Phone*</Label>
          <Input
            className={errors.phone && "is-invalid"}
            name="phone"
            placeholder="Phone"
            type="text"
            onChange={handleInputChange}
            innerRef={register({ required: true })}
          ></Input>
          {errors.phone && <FormFeedback>This is required.</FormFeedback>}
        </FormGroup>

        {/* Shipper Input */}
        <FormGroup>
          <Label>Shipper Zip Code*</Label>
          <Input
            className={errors.shipperZipCode && "is-invalid"}
            name="shipperZipCode"
            placeholder="Shipper Zip Code"
            type="text"
            onChange={handleInputChange}
            innerRef={register({ required: true })}
          ></Input>
          {errors.shipperZipCode && <FormFeedback>This is required.</FormFeedback>}
        </FormGroup>

        {/* Consignee Input */}
        <FormGroup>
          <Label>Consignee Zip Code*</Label>
          <Input
            className={errors.consigneeZipCode && "is-invalid"}
            name="consigneeZipCode"
            placeholder="Consignee Zip Code"
            type="text"
            onChange={handleInputChange}
            innerRef={register({ required: true })}
          ></Input>
          {errors.consigneeZipCode && <FormFeedback>This is required.</FormFeedback>}
        </FormGroup>

        {/* Pieces Input */}
        <FormGroup>
          <Label>Pieces*</Label>
          <Input
            className={errors.pieces && "is-invalid"}
            name="pieces"
            placeholder="Pieces"
            type="number"
            onChange={handleInputChange}
            innerRef={register({ required: true })}
          ></Input>
          {errors.pieces && <FormFeedback>This is required.</FormFeedback>}
        </FormGroup>
          
        {/* Weight Input */}
        <FormGroup>
          <Label>Weight*</Label>
          <Input
            className={errors.weight && "is-invalid"}
            name="weight"
            placeholder="Weight"
            type="text"
            onChange={handleInputChange}
            innerRef={register({ required: true })}
          ></Input>
          {errors.weight && <FormFeedback>This is required.</FormFeedback>}
        </FormGroup>

        {/* Dimensions Input */}
        <FormGroup>
          <Label>Dimensions*</Label>
          <Input
            className={errors.dimensions && "is-invalid"}
            placeholder="Dimensions"
            type="text"
            name="dimensions"
            onChange={handleInputChange}
            innerRef={register({ required: true })}
          ></Input>
          {errors.dimensions && <FormFeedback>This is required.</FormFeedback>}
        </FormGroup>

        {/* Date/Time Input */}
        <FormGroup>
        <Label>Pickup or Delivery Date*</Label>
          <Input
            min={todayDate}
            className={errors.pickupDeliveryDate && "is-invalid"}
            type="date"
            name="pickupDeliveryDate"
            onChange={handleInputChange}
            placeholder="Pickup or Delivery Date"
            innerRef={register({ required: true })}
          />
          {errors.pickupDeliveryDate && <FormFeedback>This is required.</FormFeedback>}
        </FormGroup>

        {/* Message Input */}
        <FormGroup>
          <Label>Message*</Label> 
          <Input
            className={"text-area " + (errors.message && "is-invalid")}
            name="message"
            onChange={handleInputChange}  
            placeholder="Please tell us any special services requires or additional information."
            rows="4"
            type="textarea"
            innerRef={register({ required: true })}
          ></Input>
          {errors.message && <FormFeedback>This is required.</FormFeedback>}
        </FormGroup>

        {/* Submit Button */}
        <div className="text-center">
          <Button
            className="btn-square"
            color="info"
            onClick={handleSubmit(onSubmit)}
            size="md"
          >
            Send Request
          </Button>
        </div>
        {validated ? <p className="text-center pt-4 category">Form Submitted Successfully! We'll be in touch.</p> : null}
      </Form>
  );
}

export default QuoteForm;