/*eslint-disable*/
import React, { useState, useEffect } from "react";

// reactstrap components
import { Container } from "reactstrap";

import coverageSheetUrl from "../../assets/files/coverage-sheet.pdf";
import { ReactComponent as MelioLogo } from "../../assets/img/meliologo_white.svg";
import { ReactComponent as PayCargoLogo } from "../../assets/img/paycargologo.svg";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

function DarkFooter() {
    const { width: windowWidth } = useWindowDimensions();

    return (
        <footer className="footer" data-background-color="black">
            <Container fluid>
                <div className="row">
                    <div className="col-sm-12 col-md-2 d-flex flex-column">
                        <figure className="text-center">
                            <img
                                className="freight-force-logo"
                                alt="freight force logo"
                                width="50%"
                                src={require("assets/img/FreightForceLogo-white.png")}
                            />
                            <figcaption className="pt-2">
                                Freight Force
                                <br />
                                Approved Carrier
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-sm-12 col-md-2">
                        <a style={{ fontSize: "1em" }} href="/services">
                            <p>Services</p>
                        </a>
                        {/* {windowWidth > 768 ? 
                <ul>
                  <li>
                    <a href="/services#white-glove">
                      White Glove
                    </a>
                  </li>
                  <li>
                    <a href="/services#medical">
                      Medical Deliveries
                    </a>
                  </li>
                  <li>
                    <a href="/services#warehousing">
                      Warehousing
                    </a>
                  </li>
                  <li>
                    <a href="/services#special-deliveries">
                      Special Deliveries
                    </a>
                  </li>
                </ul>
                : null } */}
                        {windowWidth > 768 ? (
                            <div>
                                <p>Resources</p>
                                <ul>
                                    <li>
                                        <a
                                            href={coverageSheetUrl}
                                            target="_blank"
                                        >
                                            Coverage Sheet
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.tsa.gov/for-industry/cargo-programs#quickset-cargo_programs_3"
                                            target="_blank"
                                        >
                                            TSA Cargo Protection
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        ) : null}
                    </div>
                    {/* {windowWidth > 768 ? 
              <div className="col-sm-12 col-md-2">
                <p>Resources</p>
                <ul>
                  <li>
                    <a
                      href= { coverageSheetUrl }
                      target="_blank"
                    >
                      Coverage Sheet
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tsa.gov/for-industry/cargo-programs#quickset-cargo_programs_3"
                      target="_blank"
                    >
                      TSA Cargo Protection
                    </a>
                  </li>
                </ul>
              </div>
            : null } */}
                    <div className="col-sm-12 col-md-2">
                        <ul className="nav-links">
                            <li>
                                <a href="/about">About</a>
                            </li>
                            <li>
                                <a href="/contact-us">Contact</a>
                            </li>
                            <li>
                                <a href="/jobs">Jobs</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-12 col-md-2 payment-methods">
                        <p>Accepted Payment Methods</p>
                        <img
                            alt="Paypal logo"
                            className="paypal-logo"
                            src={require("assets/img/paypallogo.png")}
                        />
                        <MelioLogo
                            alt="Melio logo"
                            className="melio-logo"
                            src={require("assets/img/meliologo_white.svg")}
                        />
                        <PayCargoLogo
                            alt="Paycargo logo"
                            className="paycargo-logo"
                            src={require("assets/img/paycargologo.svg")}
                        />
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex flex-column company-info">
                        <p>Unlimited Transportation</p>
                        <p>2151–2181 International St</p>
                        <p>Columbus, OH 43228</p>
                        <a href="tel:614-351-0400">Office: (614) 351-0400</a>
                        <a href="tel:614-351-0402">Fax: (614) 351-0402</a>
                        <a href="mailto:info@unlimitedtransportation.com">
                            info@unlimitedtransporation.com
                        </a>
                        <a
                            className="facebook"
                            href="https://www.facebook.com/UNLMCMH"
                            target="_blank"
                        >
                            <i className="fab fa-facebook"></i>
                        </a>
                    </div>
                </div>

                <div
                    className="copyright d-flex justify-content-center"
                    id="copyright"
                >
                    © {new Date().getFullYear()} Designed by Alt Creative, LLC
                </div>
            </Container>
        </footer>
    );
}

export default DarkFooter;
