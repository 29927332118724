import React from "react";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";

import logo from '../../assets/img/UnlimLogoFinal-resize.png';

import coverageSheetUrl from "../../assets/files/coverage-sheet.pdf";

function DefaultNavBar(props) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  function pushRoute(e, route) {
    e.preventDefault();
    props.history.push(route);
  }

  const resourceDropdown = 
    <>
      <UncontrolledDropdown className="resources-dropdown">
        <DropdownToggle
          caret
          data-toggle="dropdown"
          href="#resouces"
          id="resourceDropdown"
          tag="a"
          onClick={e => e.preventDefault()}
        > 
        Resources
      </DropdownToggle>
      <DropdownMenu aria-labelledby="resourceDropdown">
        <DropdownItem href={ coverageSheetUrl } target="_blank">
          Coverage Sheet
        </DropdownItem>
        <DropdownItem href="https://www.tsa.gov/for-industry/cargo-programs#quickset-cargo_programs_3" target="_blank">
          TSA Cargo Programs
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  </>;

  const resourceCollapse = 
    <>
      <NavItem 
        header="Resources"
        className="resources-header">
        Resources
      </NavItem>
      <NavItem>
        <NavLink href={ coverageSheetUrl } target="_blank">
          Coverage Sheet
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="https://www.tsa.gov/for-industry/cargo-programs#quickset-cargo_programs_3" target="_blank">
          TSA Cargo Programs
        </NavLink>
      </NavItem>
    </>;

  return (
    <>
      <Navbar className={"fixed-top main-nav"} expand="lg">
          <div className="navbar-translate">
            <NavbarBrand
              href="/"
              id="navbar-brand"
              onClick={e => pushRoute(e, '/')}
            >
              <img alt="company logo" src={logo}/>
            </NavbarBrand>

            <button
              className="navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
              className="justify-content-end"
              isOpen={collapseOpen}
              navbar
              id="navbarToggle"
            >
            <Nav navbar>
              <NavItem header="services">
                <NavLink href="/services" onClick={e => pushRoute(e, '/services')}>
                  Services
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/about" onClick={e => pushRoute(e, '/about')}>
                  About
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/contact-us" onClick={e => pushRoute(e, '/contact-us')}>
                  Contact
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/jobs" onClick={e => pushRoute(e, '/jobs')}>
                  Jobs
                </NavLink>
              </NavItem>

              {/* Renders Dropdown Only on Large Screens */}
              {collapseOpen ? resourceCollapse : resourceDropdown}

            </Nav>
            </Collapse>
      </Navbar>
    </>
  );
}

export default DefaultNavBar;