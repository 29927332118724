import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import {
  Input,
  FormGroup,
  Label,
  FormFeedback
  } from "reactstrap";

function OfficeQuestions(props) {
  const { handleFormEntry } = props;

  const handleInputChange = e => {
    handleFormEntry(e)
  }

  const { register, errors } = useFormContext();

  return(
    <>
      <p style={{fontSize: ".8em"}}>
        Unlimited Transportation Corp complies with the ADA and considers reasonable accommodation measures that may be necessary for eligible applicants/employees to perform essential functions. 
      </p>
      {/* */}
      <Label>Do you have any experience working in the transportation or logistics industry?*</Label>
      <ErrorMessage 
        errors={errors} 
        name="previousExperience" 
        message="This is required." 
        as={
          <p 
            className="invalid-feedback"
            style={{display:"block"}}
          >
            This is required.
          </p>
        }
      />
        <FormGroup check className="form-check-radio">
          <Label check>
            <Input
              innerRef={register({ required: true})}
              defaultValue="Yes"
              name="previousExperience"
              type="radio"
              onChange={handleInputChange}
            ></Input>
              <span className="form-check-sign"></span>
              Yes
          </Label>
        </FormGroup>
        <FormGroup check className="form-check-radio">
          <Label check>
            <Input
              innerRef={register({ required: true})}
              defaultValue="No"
              name="previousExperience"
              type="radio"
              onChange={handleInputChange}
            ></Input>
              <span className="form-check-sign"></span>
              No
          </Label>
        </FormGroup>
        
        {/*  */}
        <Label>Do you have any computer skills?*</Label>
        <ErrorMessage 
          errors={errors} 
          name="computerSkills" 
          message="This is required." 
          as={
            <p 
              className="invalid-feedback"
              style={{display:"block"}}
            >
              This is required.
            </p>
          }
        />
        <FormGroup check className="form-check-radio">
          <Label check>
            <Input
              innerRef={register({ required: true})}
              defaultValue="Yes"
              name="computerSkills"
              type="radio"
              onChange={handleInputChange}
            ></Input>
              <span className="form-check-sign"></span>
              Yes
          </Label>
        </FormGroup>
        <FormGroup check className="form-check-radio">
          <Label check>
            <Input
              innerRef={register({ required: true})}
              defaultValue="No"
              name="computerSkills"
              type="radio"
              onChange={handleInputChange}
            ></Input>
              <span className="form-check-sign"></span>
              No
          </Label>
        </FormGroup>
        
        {/* */}
        <Label>Which programs?*</Label>
        <FormGroup>
          <Input
            className={errors.computerPrograms && "is-invalid"}
            innerRef={register({ required: true})}
            name="computerPrograms"
            placeholder="Word, Excel, Email, etc."
            type="text"
            onChange={handleInputChange}
            required
          ></Input>
          <FormFeedback>This is required.</FormFeedback>
        </FormGroup>

        {/* */}
        <Label>How many words can you type per minute?*</Label>
        <FormGroup>
          <Input
            className={errors.wpm && "is-invalid"}
            innerRef={register({ required: true})}
            name="wpm"
            placeholder="WPM"
            type="text"
            onChange={handleInputChange}
            required
          ></Input>
          <FormFeedback>This is required.</FormFeedback>
        </FormGroup>

        {/*  */}
        <Label>Do you work well with others?*</Label>
        <ErrorMessage 
          errors={errors} 
          name="worksWithOthers" 
          message="This is required." 
          as={
            <p 
              className="invalid-feedback"
              style={{display:"block"}}
            >
              This is required.
            </p>
          }
        />
        <FormGroup check className="form-check-radio">
          <Label check>
            <Input
              innerRef={register({ required: true})}
              defaultValue="Yes"
              name="worksWithOthers"
              type="radio"
              onChange={handleInputChange}
            ></Input>
              <span className="form-check-sign"></span>
              Yes
          </Label>
        </FormGroup>
        <FormGroup check className="form-check-radio">
          <Label check>
            <Input
              innerRef={register({ required: true})}
              defaultValue="No"
              name="worksWithOthers"
              type="radio"
              onChange={handleInputChange}
            ></Input>
              <span className="form-check-sign"></span>
              No
          </Label>
        </FormGroup>
      </>
  );
}

export default OfficeQuestions;