import React from "react";

// reactstrap components
import {
  Button,
  TabContent,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
} from "reactstrap";

// core components
import DefaultNavBar from "components/Navbars/DefaultNavBar.js";
import ServicesHeader from "components/Headers/ServicesHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import SpecialDeliveriesTab from "./SpecialDeliveriesTab";
import WarehousingTab from "./WarehousingTab";
import WhiteGloveTab from "./WhiteGloveTab";
import MedicalTab from "./MedicalTab";
import QuoteForm from "components/Forms/QuoteForm.js";

function ServicesPage(props) {
  const [modal1, setModal1] = React.useState(false);

  let defaultPillState = "1";
  let scrollToBottom = false;
  if (props && props.history && props.history.location) {
    switch (props.history.location.hash) {
      case '#special-deliveries':
        defaultPillState = '1';
        scrollToBottom = true;
        break;
      case '#warehousing':
        defaultPillState = '2';
        scrollToBottom = true;
        break;
      case '#white-glove':
        defaultPillState = '3';
        scrollToBottom = true;
        break;
      case '#medical':
        defaultPillState = '4';
        scrollToBottom = true;
        break;
      default:
        defaultPillState = '3';
        scrollToBottom = false;
    }
  }

  if (scrollToBottom) {
    document.body.scrollTo({ x:0, y: 10000 });
  }

  const [pills, setPills] = React.useState(defaultPillState);
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <DefaultNavBar {...props} />
      <div className="wrapper ">
        <ServicesHeader />
        <div className="section ">
          <Container fluid>
            <div className="button-container">
              <Button
                color="info"
                className="mr-1 btn-square"
                size="lg"
                onClick={() => setModal1(true)}
              >
                Get a Quote
              </Button>
              <Modal isOpen={modal1} toggle={() => setModal1(false)}>
                <div className="modal-header justify-content-center">
                  <button
                    className="close"
                    type="button"
                    onClick={() => setModal1(false)}
                  >
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </button>
                  <h4 className="title title-up">Request a Quote</h4>
                </div>
                <ModalBody>
                  <QuoteForm />
                </ModalBody>
              </Modal>
            </div>
            <Row >
            <Col className="ml-auto mr-auto mt-5" md="10">

                <p className="lead text-center">
                  We have the knowledge, expertise, and equipment to get your shipment from point A to point B safely and efficiently.
                  <br />
                  <br />
                  First mile or final mile, we are here to help you help your customer!
                </p>
                <div className="text-center">
                  <img
                    alt="Container Freight Station logo"
                    className="cfs-logo"
                    src={require("assets/img/services/CFS.jpg")}
                  />
                </div>
            </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto justify-content-center" md="8">
                <h2 className="title text-center">Services</h2>
                <div 
                  className="nav-align-center services-tabs d-flex flex-wrap flex-row justify-content-between"
                >

                {/*  */}
                <div 
                    className={"service-icon col " + (pills === "3" ? "active" : "")}
                  >
                    <Button
                      className="btn-icon btn-square"
                      color="info"
                      href="/services#white-glove"
                      onClick={e => {
                        e.preventDefault();
                        setPills("3");
                      }}
                    >
                      <i className="fas fa-hands"></i>
                    </Button>
                    <h3>White Glove</h3>
                  </div>

                  {/*  */}
                  <div 
                    className={"service-icon col " + (pills === "4" ? "active" : "")}
                  >
                    <Button
                      className="btn-icon btn-square"
                      color="info"
                      href="/services#medical"
                      onClick={e => {
                        e.preventDefault();
                        setPills("4");
                      }}
                    >
                      <i className="fas fa-first-aid"></i>
                    </Button>
                    <h3>Medical Deliveries</h3>
                  </div>

                  {/*  */}
                  <div 
                    className={"service-icon col " + (pills === "2" ? "active" : "")}
                  >
                    <Button
                      className={"btn-icon btn-square " + (pills === "2" ? "active" : "")}
                      color="info"
                      href="/services#warehousing"
                      onClick={e => {
                        e.preventDefault();
                        setPills("2");
                      }}
                    >
                      <i className="fas fa-warehouse"></i>
                    </Button>
                    <h3>Warehousing</h3>
                  </div>

                {/*  */}
                <div 
                  className={"service-icon col " + (pills === "1" ? "active" : "")}
                >
                    <Button
                      className="btn-icon btn-square"
                      color="info"
                      href="/services#special-deliveries"
                      onClick={e => {
                        e.preventDefault();
                        setPills("1");
                      }}
                    >
                      <i className="fas fa-box"></i>
                    </Button>
                    <h3>Special Deliveries</h3>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <TabContent className="gallery" activeTab={"pills" + pills}>
                  <SpecialDeliveriesTab />
                  <WarehousingTab />
                  <WhiteGloveTab />
                  <MedicalTab />
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default ServicesPage;
